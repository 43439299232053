@import url('https://fonts.googleapis.com/css2?family=Exo+2&family=Mulish:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

img {
  object-fit: scale-down;
}

.custom-row-class {
  background-color: #ff7f7f;
}

.custom-row-class-2 {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em; /* Add this line to enable horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Add styles for scrollable container */
.scrollable-container {
  max-height: 200px; /* Set maximum height */
  max-width: 100%; /* Set maximum width */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}
